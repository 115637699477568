import { defineMessages } from 'react-intl';

const messages = defineMessages({
  nameMenuItemLabel: {
    id: 'General.nameLabel',
    defaultMessage: 'Name',
  },
  continueLearningTitle: {
    id: 'Courses.continueLearningTitle',
    defaultMessage: 'Continue learning',
    description: 'Title displayed in courses list page',
  },
  startLearningTitle: {
    id: 'Courses.startLearningTitle',
    defaultMessage: 'Start learning',
    description: 'Title displayed in courses list page',
  },
  addNewCourseTitle: {
    id: 'Courses.addNewCourseTitle',
    defaultMessage: 'Add new course',
    description:
      'Tooltip title displayed in courses list page to add new course',
  },
  noCoursesHereLabel: {
    id: 'Courses.noCoursesHereLabel',
    defaultMessage: 'Nothing here yet',
    description: 'The label displayed when courses list is empty on main page',
  },
  noLessonsHereLabel: {
    id: 'Courses.noLessonsHereLabel',
    defaultMessage: 'Nothing here yet',
  },
  noResultsHere: {
    id: 'Courses.noResultsHere',
    defaultMessage: 'Nothing was found with specified filters',
  },
  courseTitle: {
    id: 'Courses.courseTitle',
    defaultMessage: 'Course',
    description: 'Course title displayed in courses list page',
  },
  courseLessonsCountTitle: {
    id: 'Courses.courseLessonsCountTitle',
    defaultMessage: '{lessons} lessons ({hours} h {minutes} min)',
    description: 'Course info displayed in courses list page near each course',
  },
  taskToDoText: {
    id: 'Courses.taskToDoText',
    defaultMessage: 'You have {amount} task to do',
    description:
      'Amount of tasks todo displayed in courses list page near each course',
  },
  examScoreText: {
    id: 'Courses.examScoreText',
    defaultMessage: ' You last scored {score} at {examName} exam',
    description: 'Last exam score displayed in courses list page near course',
  },
  quizScoreText: {
    id: 'Courses.quizScoreText',
    defaultMessage: ' You last scored {score} at {quizName} quiz',
    description: 'Last quiz score displayed in courses list page near course',
  },
  discussionsTitle: {
    id: 'Courses.discussionsTitle',
    defaultMessage: 'Discussions',
    description: 'Discussions title displayed in courses list page near course',
  },
  classroomTitle: {
    id: 'Courses.classroomTitle',
    defaultMessage: 'Classroom',
    description: 'Classroom title displayed in courses list page near course',
  },
  followedTitle: {
    id: 'Courses.followedTitle',
    defaultMessage: 'Followed',
    description: 'Followed title displayed in courses list page near course',
  },
  hideDashboardBtnLabel: {
    id: 'Courses.hideDashboardBtnLabel',
    defaultMessage: 'Hide dashboard',
    description:
      'Hide dashboard title displayed in courses list page near course',
  },
  showDashboardBtnLabel: {
    id: 'Courses.showDashboardBtnLabel',
    defaultMessage: 'Show dashboard',
    description:
      'Show dashboard title displayed in courses list page near course',
  },
  allCoursesBtnLabel: {
    id: 'Courses.allCoursesBtnLabel',
    defaultMessage: 'All Courses',
    description: 'All Courses button title  displayed in courses list',
  },
  skholeCoursesBtnLabel: {
    id: 'Courses.skholeCoursesBtnLabel',
    defaultMessage: '{serviceNamePossessive} Courses',
    description: 'Skhole Courses button title displayed in courses list',
  },
  recentlyAccessedMenuItemLabel: {
    id: 'Courses.recentlyAccessedMenuItemLabel',
    defaultMessage: 'Recently accessed',
    description: 'Recently accessed menu item displayed in courses list',
  },
  progressMenuItemLabel: {
    id: 'Courses.progressMenuItemLabel',
    defaultMessage: 'Progress',
    description: 'Progress menu item displayed in courses list',
  },
  popularityMenuItemLabel: {
    id: 'Courses.popularityMenuItemLabel',
    defaultMessage: 'Popularity',
    description: 'Popularity menu item displayed in courses list',
  },
  orderTitle: {
    id: 'Courses.orderTitle',
    defaultMessage: 'Order',
    description: 'Order title displayed in courses list',
  },
  ascendingMenuItemLabel: {
    id: 'Courses.ascendingMenuItemLabel',
    defaultMessage: 'Ascending',
    description: 'Ascending menu item displayed in courses list in order menu',
  },
  descendingMenuItemLabel: {
    id: 'Courses.descendingMenuItemLabel',
    defaultMessage: 'Descending',
    description: 'Descending menu item displayed in courses list in order menu',
  },
  loadingBtnLabel: {
    id: 'Courses.loadingBtnLabel',
    defaultMessage: 'Loading...',
    description: 'Loading button title displayed in courses list',
  },
  loadMoreBtnLabel: {
    id: 'Courses.loadMoreBtnLabel',
    defaultMessage: 'Load more',
    description: 'Load more button title displayed in courses list',
  },
  publishDateItemLabel: {
    id: 'Courses.publishDateItemLabel',
    defaultMessage: 'Publish date',
    description: 'Publish date title displayed in courses list',
  },
  haveMessagesText: {
    id: 'Courses.haveMessagesText',
    defaultMessage: 'You have {amount} unread notification(s)',
    description: 'Number of unread messages displayed on student dashboard',
  },
  nothingToShowTitle: {
    id: 'Courses.nothingToShowTitle',
    defaultMessage: 'Nothing to show here, yet.',
    description:
      'Text is displayed on student dashboard when there is no content',
  },
  courses: {
    id: 'Courses.courses',
    defaultMessage: 'Courses',
  },
  searchCoursesResults: {
    id: 'Courses.searchCoursesResults',
    defaultMessage: '{total} results',
  },
  lessons: {
    id: 'Courses.lessons',
    defaultMessage: 'Lessons',
  },
  searchLessonsResults: {
    id: 'Courses.searchLessonsResults',
    defaultMessage: '{total} results',
  },
  updatingOfflineContent: {
    id: 'Courses.updatingOfflineContent',
    defaultMessage: 'Syncing offline mode state...',
  },
  updatedOfflineContent: {
    id: 'Courses.updatedOfflineContent',
    defaultMessage: 'Offline mode state sync finished.',
  },
  myCoursesBtn: {
    id: 'Courses.myCoursesBtn',
    defaultMessage: 'My courses',
  },
  favouriteCoursesBtn: {
    id: 'Courses.favouriteCoursesBtn',
    defaultMessage: 'Favourite courses',
  },
  clearFilterLong: {
    id: 'Courses.clearFilterLong',
    defaultMessage: 'Clear filters',
  },
  clearFilterShot: {
    id: 'Courses.clearFilterShot',
    defaultMessage: 'Clear',
  },
  applyCategoryFilter: {
    id: 'Courses.applyCategoryFilter',
    defaultMessage: 'Apply',
  },
  filtersAndOrderBtnLabel: {
    id: 'Courses.filtersAndOrderBtnLabel',
    defaultMessage: 'Filter and Order',
  },
  amountAppliedLabel: {
    id: 'Courses.amountAppliedLabel',
    defaultMessage: '{amount} applied',
  },
  serviceNamePossessive: {
    id: 'General.serviceNamePossessive',
    defaultMessage: 'Skhole',
  },
  emptyDashboard: {
    id: 'Courses.emptyDashboard',
    defaultMessage: 'Nothing to show on your dashboard yet.',
  },
  browseByCategoryBtnLabel: {
    id: 'Header.browseByCategoryBtnLabel',
    defaultMessage: 'Click to select',
    description:
      'Browse by category displayed on the button label in page header',
  },
  browseByContentCategoryBtnLabel: {
    id: 'Header.browseByContentCategoryBtnLabel',
    defaultMessage: 'Click to select',
    description:
      'Browse by category displayed on the button label in page header',
  },
  browseByCategorySelectedBtnLabel: {
    id: 'Header.browseByCategorySelectedBtnLabel',
    defaultMessage: '{amountSelected} selected',
    description:
      'Selected displayed on the button label in page header if selected > 0',
  },
  selectAllLabel: {
    id: 'Header.selectAllLabel',
    defaultMessage: 'Select All',
    description:
      'Select All displayed on popover modal after Browse by category clicked in page header',
  },
  showGrid: {
    id: 'Courses.showGrid',
    defaultMessage: 'Show grid',
  },
  showList: {
    id: 'Courses.showList',
    defaultMessage: 'Show list',
  },
  customersCourses: {
    id: 'Courses.customersCourses',
    defaultMessage: 'Other courses',
  },
  browseByCategoryBtnTooltip: {
    id: 'Courses.browseByCategoryBtnTooltip',
    defaultMessage: 'Filter courses based on categories',
  },
  allCoursesBtnTooltip: {
    id: 'Courses.allCoursesBtnTooltip',
    defaultMessage: 'Filter courses - Show all courses',
  },
  skholeCoursesBtnToolTip: {
    id: 'Courses.skholeCoursesBtnToolTip',
    defaultMessage:
      'Filter courses - Show courses created by {serviceNameToUse}',
  },
  customersCoursesToolTip: {
    id: 'Courses.customersCoursesToolTip',
    defaultMessage:
      'Filter courses - Show courses created by others than {serviceNameToUse}',
  },
  favouriteCoursesBtnTooltip: {
    id: 'Courses.favouriteCoursesBtnTooltip',
    defaultMessage:
      'Filter courses - Show courses you have marked as favourite',
  },
  myCoursesBtnTooltip: {
    id: 'Courses.myCoursesBtnTooltip',
    defaultMessage:
      'Filter courses - Show courses you have marked as favourite or that have been assigned to you',
  },
  livestreamType: {
    id: 'Courses.livestreamType',
    defaultMessage: 'Live broadcast',
  },
  podcastType: {
    id: 'Courses.podcastType',
    defaultMessage: 'Podcast',
  },
  normalType: {
    id: 'Courses.normalType',
    defaultMessage: 'Course',
  },
  allLabel: {
    id: 'General.allLabel',
    defaultMessage: 'All',
  },

  courseTypeFilterLabel: {
    id: 'Courses.courseTypeFilterLabel',
    defaultMessage: 'Content type',
  },
  languageFilterLabel: {
    id: 'Courses.languageFilterLabel',
    defaultMessage: 'Language',
  },
  classificationFilterLabel: {
    id: 'Courses.classificationFilterLabel',
    defaultMessage: 'Content filter',
  },
  categoriesBlockLabel: {
    id: 'Courses.categoriesBlockLabel',
    defaultMessage: 'Category',
  },
  tagsBlockLabel: {
    id: 'Courses.tagsBlockLabel',
    defaultMessage: 'Classification',
  },
});

export default messages;
